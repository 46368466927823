import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import dimensions from '../styles/dimensions';
import Layout from '../components/Layout';
import ToolCard from '../components/ToolCard';
import colors from 'styles/colors';
import MetaHelmet from '../components/MetaHelmet';

const ToolsTitle = styled('h1')`
  margin-bottom: 0em;
`;

const ToolsGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5em;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
    grid-gap: 2.5em;
  }
`;

const ToolSubText = styled('h6')`
  font-weight: 600;
  color: ${colors.grey600};
  margin-bottom: 1em;
  margin-left: 1em;
`;

const Tools = ({ tools, meta }) => (
  <React.Fragment>
    {console.log(' - tools', tools)}
    <MetaHelmet titleTemplate="%s ...tools  ...3dots" {...meta} />
    <Layout>
      <ToolsTitle>Tools</ToolsTitle>
      <ToolSubText>The tools of my trade, click to see related blog posts.</ToolSubText>
      <ToolsGrid>
        {tools.map(tool => (
          <ToolCard key={tool.name} {...tool} />
        ))}
      </ToolsGrid>
    </Layout>
  </React.Fragment>
);

export default ({ data }) => {
  const { edges = [] } = data.prismic.allTools || {};
  const meta = data.site.siteMetadata;
  return <Tools tools={edges.map(({ node }) => node)} meta={meta} />;
};

export const query = graphql`
  {
    prismic {
      allTools(sortBy: level_DESC) {
        totalCount
        edges {
          node {
            name
            level
            experience_start_date
            logo
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
