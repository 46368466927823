import React from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';
import dimensions from '../styles/dimensions';
import colors from '../styles/colors';
import PropTypes from 'prop-types';
import Team from './svg/team';

const ProjectCardContainer = styled(Link)`
  display: grid;
  grid-template-columns: 4fr 7fr;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
  margin-bottom: 4em;
  transition: all 150ms ease-in-out;
  text-decoration: none;
  color: currentColor;

  @media (max-width: 950px) {
    grid-template-columns: 4.5fr 7fr;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 2em;
  }

  &:hover {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    transition: all 150ms ease-in-out;

    .ProjectCardAction {
      color: ${colors.gold300};
      transition: all 150ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }

    .ProjectCardContent::before {
      opacity: 0.02;
      transition: all 150ms ease-in-out;
    }

    .ProjectCardImageContainer::before {
      opacity: 0.2;
      transition: all 150ms ease-in-out;
    }
  }
`;

const ProjectCardContent = styled('div')`
  background: white;
  padding: 4em 3em 2.25em 3em;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${colors.blue500};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  @media (max-width: 950px) {
    padding: 3.25em 2.5em 2em 2.5em;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-row: 2;
  }
`;

const ProjectCardCategory = styled('h6')`
  font-weight: 600;
  color: ${colors.grey600};
`;

const ProjectCardTitle = styled('h3')`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`;

const ProjectCardBlurb = styled('div')`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 5em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 2.5em;
  }
`;

const ProjectCardAction = styled('div')`
  font-weight: 600;
  text-decoration: none;
  color: ${colors.blue1000};
  transition: all 150ms ease-in-out;

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }
`;

// background: url(${require('./svg/good-team.svg')}) no-repeat bottom right;
const ProjectCardImageContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  padding-bottom: 1em;
  padding-right: 1em;
  background: ${({ thumbnailUrl }) => `url(${thumbnailUrl}) no-repeat center}`}
  background-size: 90%;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding-top: 3em;
    max-height: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${colors.blue300};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  svg {
    max-width: 400px;
    max-height: 400px;
    height: 100%
    width: 100%;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      max-width: 300px;
    }
  }
`;

const ProjectCard = ({ category, title, description, thumbnail, uid }) => {
  const { url } = thumbnail || {};
  return (
  <ProjectCardContainer to={`/work/${uid}`}>
    {/*<div>*/}
    {/*  <div>{RichText.render(description)} </div>*/}
    {/*  <Team />*/}
    {/*</div>*/}

    <ProjectCardContent className="ProjectCardContent">
      <ProjectCardCategory>{category.map(({ text }) => text).join(',')}</ProjectCardCategory>
      <ProjectCardTitle>{title.map(({ text }) => text).join(',')}</ProjectCardTitle>
      <ProjectCardBlurb>{RichText.render(description)}</ProjectCardBlurb>
      <ProjectCardAction className="ProjectCardAction">
        Details <span>&#8594;</span>
      </ProjectCardAction>
    </ProjectCardContent>
    <ProjectCardImageContainer className="ProjectCardImageContainer" thumbnailUrl={url}>
      {/*<Team />*/}
    </ProjectCardImageContainer>
  </ProjectCardContainer>
)};

export default ProjectCard;

ProjectCard.propTypes = {
  category: PropTypes.array.isRequired,
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
};
