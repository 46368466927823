import React from 'react';
import Helmet from 'react-helmet';

export default ({ titleTemplate, helmetTitle, ...meta }) => {
  const { description, author, title } = meta || {};
  return (
    <Helmet
      title={helmetTitle || title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  );
};
