import React from 'react';
import Moment from 'react-moment';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import colors from 'styles/colors';

const ToolCardContainer = styled(Link)`
  border: 1px solid ${colors.grey200};
  border-radius: 3px;
  min-height: 100px;
  text-decoration: none;
  color: currentColor;
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
  transition: all 150ms ease-in-out;

  .fadein {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    transition: all 150ms ease-in-out;
    cursor: pointer;

    .PostCardAction {
      color: ${colors.blue500};
      transition: all 150ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }
  }
`;

const ToolCardBackground = styled('div')`
  width: inherit;
  height: 100%;
  padding: 3em 2.5em 2.25em 2.5em;
  opacity: 0.35;
  transform: scale(0.95);
  clip-path: inset(0% 0% 0% 0%);
  transition: 1s;
  background: ${({ logoUrl }) => `no-repeat right/20% url(${logoUrl})`}};

  ${ToolCardContainer}:hover & {
    transform: scale(1.5);
    clip-path: inset(20%);
    opacity: 1;
    background-position: center;
  }
`;

const ToolInfoContainer = styled('div')`
  opacity: 0.96;
  transform: 2s;
  width: inherit;
  height: 100%;
  margin-top: 1em;
  margin-left: 1.5em;
  position: absolute;
  transition: opacity 0.4s;

  ${ToolCardContainer}:hover & {
    opacity 0;
  }
`;

const ToolSubText = styled('h6')`
  font-weight: 600;
  color: ${colors.grey700};
`;

const LengthOfExperience = styled(Moment)`
  font-weight: 300;
`;

const ToolName = styled('h3')`
  margin: 0;
`;

const Level = styled('div')`
  bottom: 0;
  right: 0;
`;

const PostCard = ({ name, level, logo, experience_start_date, className }) => (
  <ToolCardContainer className={className} to={`/tools`}>
    <ToolCardBackground logoUrl={logo.url} />
    <ToolInfoContainer>
      <ToolName>{name}</ToolName>
      <ToolSubText>
        <Level>{level}</Level>
        <LengthOfExperience parse="YYYY-MM-DD" date={experience_start_date} durationFromNow />
      </ToolSubText>
    </ToolInfoContainer>
  </ToolCardContainer>
);

export default PostCard;
