import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from 'components/Layout';
import ProjectCard from 'components/ProjectCard';
import MetaHelmet from '../components/MetaHelmet';

const WorkTitle = styled('h1')`
  margin-bottom: 1em;
`;

const Work = ({ projects, meta }) => (
  <>
    <MetaHelmet titleTemplate={`%s ...work ...3dots`} {...meta} />
    <Layout>
      <WorkTitle>Work</WorkTitle>
      <>
        {projects.map((project, i) => (
          <ProjectCard
            key={i}
            category={project.node.project_category}
            title={project.node.project_title}
            description={project.node.project_preview_description}
            thumbnail={project.node.project_preview_thumbnail}
            uid={project.node._meta.uid}
          />
        ))}
      </>
    </Layout>
  </>
);

export default ({ data }) => {
  const projects = data.prismic.allProjects.edges;
  const meta = data.site.siteMetadata;
  if (!projects) return null;

  return <Work projects={projects} meta={meta} />;
};

Work.propTypes = {
  projects: PropTypes.array.isRequired,
};

export const query = graphql`
  {
    prismic {
      allProjects {
        edges {
          node {
            project_title
            project_preview_description
            project_preview_thumbnail
            project_category
            project_post_date
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
